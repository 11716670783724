import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { cloneElement, FC, ReactElement, useEffect, useState } from "react";
import { CustomedButton, InvoicesNotSettledTable } from "../..";
import { usePaymentsDetails } from "../../../../hooks/usePaymentsDetails";
import { usePaymentSend } from "../../../../hooks/usePaymentSend";
import { useUserData } from "../../../../hooks/useUserData";
import { useUserPaymentChannels } from "../../../../hooks/useUserPaymentChannels";
import { ChangeUserDataModal } from "../..";

interface IInvoice {
  id: string;
  name: string;
  cost: string;
  checked: boolean;
}

export interface IPaymentModal {
  invoice?: IInvoice;
  button?: ReactElement;
}

export const PaymentModal: FC<IPaymentModal> = ({ invoice, button }) => {
  const user = useUserData();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [invoicesSelectedDefault, setInvoicesSelectedDefault] = useState(true);
  const [step, setStep] = useState(1);
  const [channel, setChannel] = useState("0");
  const [isHover, setIsHover] = useState(false);

  const paymentChannels = useUserPaymentChannels();
  const { isLoading, isError, isSuccess, errors, paymentSend, reset } = usePaymentSend();
  const { data: detailsData } = usePaymentsDetails();

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess])

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleCheck = (invoice: IInvoice) => {
    if (invoice.checked) {
      if (!invoices.find((element) => element.id === invoice.id)) {
        setInvoices((oldState) => {
          return [...oldState, invoice];
        });
      }
    } else {
      if (invoices.find((element) => element.id === invoice.id)) {
        const index = invoices.findIndex((element) => element.id === invoice.id);

        setInvoices((oldState) => {
          oldState.splice(index, 1);
          return [...oldState];
        });
      } else {
        setInvoices((oldState) => {
          return [...oldState, invoice];
        });
      }
    }
  };

  const handleOnClose = () => {
    changeStep(1)
    onClose()
  }

  const handleSubmit = () => {
    if (invoices) {
      paymentSend({
        channel_id: channel,
        invoices: invoices.map(({ id }) => id),
      });
      // onClose();
    }
  };

  const changeStep = (newStep: number) => {
    setInvoicesSelectedDefault(step <= newStep);
    setStep(newStep);
  };

  useEffect(() => {
    if (paymentChannels.data.length === 1) {
      // console.log('paymentChannels: ' + JSON.stringify(paymentChannels.data))
      // console.log('paymentChannels length: ' + paymentChannels.data.length)
      setChannel(paymentChannels.data[0]?.id)
    } else if (paymentChannels.data.length !== 0) {
      console.log('nalezy wybrac metode platnosci')
      // console.log('metoduy platnosci: ' + JSON.stringify(paymentChannels.data))
      // console.log('dlugosc metod platnosci: ' + JSON.stringify(paymentChannels.data.length))
    }
  }, [paymentChannels]);

  useEffect(() => {
    if (invoice) {
      setStep(2);
      setInvoices([invoice]);
    }
  }, [invoice]);

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: onOpen })
      ) : (
        <CustomedButton
          centered={true}
          fontSize="14px"
          height="35px"
          label="Kontakt"
          onClick={onOpen}
        />
      )}

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay onClick={handleOnClose} />
        <ModalContent
          minW={["100%", "100%", "600px"]}
          minH={["100%", "100%", "initial"]}
          m={["0px", "0px", "60px 0px"]}
          borderRadius={["0px", "0px", "10px"]}
        >
          <ModalHeader color="darkPrimary">Płatność</ModalHeader>
          <ModalCloseButton
            fontSize={["20px", "20px", "12px"]}
            m={["10px", "10px", "0px"]}
          />
          <ModalBody minHeight="200px">
            {step === 1 && (
              <>
                <Text color="black" fontSize="14px">
                  Wybierz faktury, które chcesz opłacić
                </Text>
                {/* <Box display={["block", "block", "block"]} boxShadow={["md", "md", "md"]} borderLeftRadius="10px" maxH={'500px'} overflowY='auto' > */}
                <InvoicesNotSettledTable
                  blocked={detailsData?.blocked}
                  defaultSelected={invoicesSelectedDefault}
                  checked={invoices.map(({ id }) => id)}
                  handleCheck={handleCheck}
                />
                {/* </Box> */}

                {detailsData?.blocked > 1 && (
                  <Text color="red" fontSize="14px">
                    <Text color="red" fontSize="16px">
                      UWAGA
                    </Text>
                    Całkowita suma do zapłaty może obejmować dodatkowe zobowiązania, takie jak noty odsetkowe i inne zaległości
                  </Text>
                )}
              </>
            )}
            {step === 2 && (
              <>
                <Flex flexWrap="wrap" mb="30px">
                  {isError && (
                    <Alert status="error" borderRadius="8px" mb="10px">
                      <AlertIcon />
                      <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                      <AlertDescription mr={"15px"}>
                        <ChangeUserDataModal
                          button={
                            <span
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave} style={{
                                cursor: "pointer",
                                paddingLeft: "10px",
                                textDecorationLine: isHover ? 'underline' : 'none',
                                color: isHover ? 'var(--chakra-colors-lightPrimary)' : 'black',

                              }} onClick={() => { onClose() }}>
                              {errors.join(" ")}
                            </span>
                          }
                        />
                      </AlertDescription>
                      <CloseButton
                        position="absolute"
                        right="8px"
                        top="8px"
                        onClick={reset}
                      />
                    </Alert>
                  )}
                  <Box mr="40px">
                    <Text fontSize="14px" color="lightGray">
                      Imię i nazwisko
                    </Text>
                    <Text fontSize="18px" fontWeight={600} color="black">
                      {user.isLoading ? "" : (user.data ? user.data.name + " " + user.data.lastname : "")}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="14px" color="lightGray">
                      Email
                    </Text>
                    <Text fontSize="18px" fontWeight={600} color="black">
                      {user.isLoading ? "" : (user.data ? user.data.email : "")}
                    </Text>
                  </Box>
                </Flex>
                <Flex justifyContent={["flex-start", "flex-start", "space-between"]} flexWrap={["wrap", "wrap", "nowrap"]} mb="30px">
                  <Box mr="20px">
                    <Text fontSize="14px" color="lightGray">
                      Dokument
                    </Text>
                    <Text fontSize="18px" fontWeight={600} color="black" dangerouslySetInnerHTML={{
                      __html: invoices.map(({ name }) => name).join(",<br/>")
                        + (detailsData.blocked > 0 && detailsData && detailsData.other_payments ? ',<br/>Noty odsetkowe i obciążeniowe' : '')
                    }} />
                  </Box>
                  <Box mr="20px">
                    <Text fontSize="14px" color="lightGray">
                      Kwota
                    </Text>
                    <Text fontSize="18px" fontWeight={600} color="black">
                      {(invoices
                        .map(({ cost }) => Number(cost))
                        .reduce((partialSum, a) => partialSum + a, 0) +
                        Number(detailsData.blocked > 0 ? (detailsData?.other_payments_value || 0) : 0)
                      ).toFixed(2)}{" "}
                      zł
                    </Text>
                  </Box>
                  <Box mr="20px">
                    <Text fontSize="14px" color="lightGray">
                      Opłata
                    </Text>
                    <Text fontSize="18px" fontWeight={600} color="black">
                      {detailsData?.fee || 0} zł
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="14px" color="lightGray">
                      Do zapłaty
                    </Text>
                    <Text fontSize="18px" fontWeight={600} color="lightPrimary">
                      {(
                        invoices
                          .map(({ cost }) => Number(cost))
                          .reduce((partialSum, a) => partialSum + a, 0) +
                        Number(detailsData?.fee || 0) + Number(detailsData.blocked > 0 ? (detailsData?.other_payments_value || 0) : 0)
                      ).toFixed(2)}{" "}
                      zł
                    </Text>
                  </Box>
                </Flex>
                {paymentChannels.data.length > 1 ?
                  <>
                    <Text fontSize="14px" color="lightGray">
                      Wybierz metodę płatności:
                    </Text>
                    <Flex
                      mt="30px"
                      justify="space-evenly"
                      flexWrap={["wrap", "wrap", "wrap"]}
                    >
                      {paymentChannels?.data.map(({ id, name, logo }) => (
                        <Box
                          key={id}
                          height="100px"
                          width="100px"
                          cursor="pointer"
                          // border={
                          //   "solid " +
                          //   (channel === id ? "2px darkPrimary" : "1px #979797")
                          // }
                          backgroundColor={channel == id ? 'lightPrimary' : ''}
                          borderRadius="10px"
                          m="2px"
                          _hover={{ backgroundColor: "backgroundPrimary" }}
                          onClick={() => {
                            setChannel(id);
                          }}
                        >
                          {/*<Text*/}
                          {/*  color={channel === id ? "darkPrimary" : "lightGray"}*/}
                          {/*  fontSize="14px"*/}
                          {/*  textAlign="center"*/}
                          {/*>*/}
                          {/*  {name}*/}
                          {/*</Text>*/}
                          <Flex justify="center"
                            style={{ marginTop: "50%", transform: "translateY(-50%)" }}>
                            <Image src={logo} />
                          </Flex>
                        </Box>
                      ))}
                    </Flex>
                  </>
                  : null
                }
              </>
            )}
          </ModalBody>

          <ModalFooter justifyContent="center">
            <Flex
              justifyContent="center"
              w="100%"
              flexDir={["column-reverse", "column-reverse", "row"]}
            >
              {step === 1 && (
                <Button
                  backgroundColor="lightPrimary"
                  _hover={{ backgroundColor: "lighterPrimary" }}
                  color="white"
                  p="10px 30px"
                  size={"sm"}
                  disabled={!invoices.length}
                  onClick={() => changeStep(2)}
                >
                  Przejdź dalej
                </Button>
              )}
              {step === 2 && !invoice && (
                <Button
                  p="10px 30px"
                  mr={["0", "0", "15px"]}
                  size={"sm"}
                  onClick={() => changeStep(1)}
                >
                  Wróć
                </Button>
              )}
              {step === 2 && (
                <Button
                  backgroundColor="lightPrimary"
                  _hover={{ backgroundColor: "lighterPrimary" }}
                  color="white"
                  p="10px 30px"
                  mb={["10px", "10px", "0"]}
                  size={"sm"}
                  isDisabled={channel === "0"}
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  Zapłać
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
