import {
    Button,
    Container,
    Fade,
    Flex,
    Grid,
    GridItem,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import { FC, useState } from "react";

import {
    AgreementsCard,
    Banner,
    Card,
    ContactFormModal,
    CustomedButton,
    CustomedSpinner,
    FinanceLastPaymentCard,
    FinanceSettlementsCard,
    PageHeader,
    PaymentModal,
} from "../../shared/components";
import { InvoicesTable, PaymentsTable } from "../../shared/components/Tables";

import { ServicesTable } from "../../shared/components/Tables/Services";
import {
    useInvoicesSettledDict,
    useInvoicesYearDict,
} from "../../hooks/useInvoicesDict";
import { generateOptions } from "../../shared/utils";
import { IQueryOptions } from "../../core/types";

import bannerImage from "../../../src/img/bannerSettlements/rozliczenia_top.jpg";
import { useInvoicesLast } from "../../hooks/useInvoicesLast";
import { useUserData } from "../../hooks/useUserData";
import { BannerClass } from "../../shared/components/Banner/utils";
import { endpoints } from "../../api/endpoints";
import { downloadFromUrl } from "../../api/http";
import { useFirm } from "../../hooks/useFirm";
import { env } from "../../env";

export const Finance: FC = () => {

    const [invoicesFilters, setInvoicesFilters] = useState<
        IQueryOptions["filters"]
    >({}),

        settledDict = useInvoicesSettledDict(),
        yearDict = useInvoicesYearDict(),

        invoicesLast = useInvoicesLast(),
        userData = useUserData(),

        bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno),

        firm = useFirm(),

        { ebok_payments } = firm.data,

        handleInvoicesSelectChange = (name: string, value: string) => {
            setInvoicesFilters((oldState) => {
                let newState = { ...oldState };
                if (value) {
                    newState[name] = value;
                } else {
                    delete newState[name];
                }

                return newState;
            });
        },

        handleDownload = () => {
            downloadFromUrl(endpoints.payments.download);
        };

    if (invoicesLast.isLoading && !invoicesLast.data && userData.isLoading) {
        return <CustomedSpinner />;
    }

    return (
        <>
            <Banner
                extraContent={
                    <ContactFormModal
                        button={
                            <CustomedButton
                                centered={true}
                                fontSize="22px"
                                height="50px"
                                label="Przedłuż umowę"
                            />
                        }
                        action="contract"
                    />
                }
                bannerImage={bannerImage}
                {...bannerDetails}
            />
            <Fade in={true}>
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <PageHeader title="Finanse" onlyMobile />
                    <Grid templateColumns={["repeat(2, 1fr)"]} gap={30} mb="20px">
                        {/* Rozliczenia */}
                        <GridItem colSpan={[2, 2, 1, 1]}>
                            <FinanceSettlementsCard />
                        </GridItem>

                        {/* Ostatnia płatność */}
                        <GridItem colSpan={[2, 2, 1, 1]}>
                            <FinanceLastPaymentCard />
                        </GridItem>

                        {/* Umowy i aneksy */}
                        <GridItem colSpan={2}>
                            <AgreementsCard />
                        </GridItem>

                        {/* Faktury / Usługi / Aneksy */}
                        <GridItem colSpan={2} width={'100%'} maxWidth={'container.xl'} >
                            <Tabs width={'100%'} minHeight={["0px", "0px", "800px"]}>
                                <TabList width={'100%'}>
                                    <Tab
                                        fontSize="20px"
                                        fontWeight="600"
                                        style={{ boxShadow: "none" }}
                                        // width={'100%'}
                                    >
                                        Faktury
                                    </Tab>
                                    <Tab
                                        fontSize="20px"
                                        fontWeight="600"
                                        style={{ boxShadow: "none" }}
                                    >
                                        Usługi
                                    </Tab>
                                    <Tab
                                        fontSize="20px"
                                        fontWeight="600"
                                        style={{ boxShadow: "none" }}
                                    >
                                        Wpłaty
                                    </Tab>
                                </TabList>

                                <TabPanels >
                                    <TabPanel >
                                        <Card
                                            title="Faktury"
                                            displayHeaderOnMobile={false}
                                            childrenStyle={{ padding: '10px'/* , overflowX: 'auto' */ }}
                                            extraContent={
                                                <Flex justifyContent="flex-end">
                                                    {ebok_payments ? (
                                                        <PaymentModal
                                                            button={
                                                                <CustomedButton label="Opłać ON-LINE" mr="15px" />
                                                            }
                                                        />
                                                    ) : ''}
                                                    <Button
                                                        backgroundColor="darkerPrimary"
                                                        _hover={{ backgroundColor: "lightPrimary" }}
                                                        mr="15px"
                                                        color="white"
                                                        onClick={handleDownload}
                                                    >
                                                        Pobierz harmonogram wpłat
                                                    </Button>
                                                    <Flex>
                                                        <Select
                                                            placeholder="Status: Wszystkie"
                                                            color="darkPrimary"
                                                            borderColor="darkPrimary"
                                                            minW="170px"
                                                            mr="15px"
                                                            onChange={(change) =>
                                                                handleInvoicesSelectChange(
                                                                    "settled",
                                                                    change.target.value
                                                                )
                                                            }
                                                        >
                                                            {settledDict.data &&
                                                                generateOptions(settledDict.data)}
                                                        </Select>
                                                        <Select
                                                            size="md"
                                                            placeholder="Rok: Wszystkie"
                                                            color="darkPrimary"
                                                            borderColor="darkPrimary"
                                                            onChange={(change) =>
                                                                handleInvoicesSelectChange(
                                                                    "TrN_DataDok_year",
                                                                    change.target.value
                                                                )
                                                            }
                                                        >
                                                            {yearDict.data && generateOptions(yearDict.data)}
                                                        </Select>
                                                    </Flex>
                                                </Flex>
                                            }
                                        >
                                            <InvoicesTable filters={invoicesFilters} />
                                        </Card>
                                    </TabPanel>
                                    <TabPanel width={'100%'} >
                                        <Card title="Usługi" displayHeaderOnMobile={false}>
                                            <ServicesTable />
                                        </Card>
                                    </TabPanel>
                                    <TabPanel width={'100%'} >
                                        <Card title="Wpłaty" displayHeaderOnMobile={false}>
                                            <PaymentsTable />
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </GridItem>
                    </Grid>
                </Container>
            </Fade>
        </>
    );
};
