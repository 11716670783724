import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ChangeEvent, FC, useState } from "react";
import { CustomedButton, CustomedInput } from "../..";
import { useTelevisionPackageActivation } from "../../../../hooks/useTelevisionPackageActivation";

export interface IPackageActivation {
  iptv_account_id: string;
  iptv_cust_master_pin: string;
  package_id: string;
}

export interface IBuyPackageModal {
  mobileVersion?: boolean;
  name: string;
  cost: string;
  package_id: string;
  iptvAccountId: string;
}

export const BuyPackageModal: FC<IBuyPackageModal> = ({
  mobileVersion,
  name,
  cost,
  package_id,
  iptvAccountId,
}) => {
  mobileVersion = mobileVersion || undefined
  const {
    isLoading,
    isSuccess,
    isError,
    errors,
    televisionPackageActivation,
    resetForm,
  } = useTelevisionPackageActivation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputs, setInputs] = useState<IPackageActivation>({
    iptv_account_id: iptvAccountId,
    iptv_cust_master_pin: "",
    package_id,
  });

  const handleInputChange = (key: string, value: string) => {
    setInputs((oldState) => {
      const newState = { ...oldState, [key]: value };
      return newState;
    });
  };

  const onSubmit = (values: IPackageActivation) => {
    if (values) {
      televisionPackageActivation({
        iptv_accountid: values.iptv_account_id,
        iptv_cust_master_pin: values.iptv_cust_master_pin,
        packageid: values.package_id,
      });
    }
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <CustomedButton
        centered={true}
        fontSize="14px"
        height="30px"
        label={mobileVersion ? "Włącz" : "Włącz pakiet"}
        size="sm"
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay onClick={handleClose} />
        {/* <ModalContent minW="500px"> */}
        <ModalContent maxW={'500px'}  >
          <ModalHeader color="darkPrimary">
            Włącz pakiet
            <Text color="lightPrimary" fontSize="14px" fontWeight={600}>
              Włączając pakiet zgadzasz się na dodatkowe opłaty abonamentowe!
            </Text>
          </ModalHeader>

          <ModalCloseButton
            fontSize={["20px", "20px", "12px"]}
            m={["10px", "10px", "0px"]}
          />
          <ModalBody>
            {isSuccess ? (
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                minHeight="200px"
                borderRadius="8px"
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Zamówienie zostało złożone.
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  Pakiet powinien zostać aktywowany w przeciągu kilku minut.
                </AlertDescription>
              </Alert>
            ) : (
              <>
                {isError && (
                  <Alert status="error" borderRadius="8px" mb="10px">
                    <AlertIcon />
                    <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                    <AlertDescription mr={"15px"}>
                      {errors.join(" ")}
                    </AlertDescription>
                    <CloseButton
                      position="absolute"
                      right="8px"
                      top="8px"
                      onClick={resetForm}
                    />
                  </Alert>
                )}
                <Text fontSize="14px" color="basicBlack">
                  PAKIET
                </Text>
                <Text fontSize="24px" color="black" mb="25px">
                  {name}
                </Text>

                <Text fontSize="14px" color="basicBlack">
                  ŁĄCZNY KOSZT (brutto)
                </Text>
                <Text fontSize="24px" fontWeight={600} color="lightPrimary">
                  {cost} zł
                </Text>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex
              flexDir={["column", "column", "row"]}
              justifyContent="space-between"
              w="100%"
            >
              {!isSuccess && (
                <>
                  <CustomedInput
                    label="Twój PIN"
                    placeholder="Podaj numer PIN"
                    type="password"
                    autoComplete="off"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(
                        "iptv_cust_master_pin",
                        event.target.value
                      );
                    }}
                  />
                  <Button
                    backgroundColor="lightPrimary"
                    color="white"
                    p="10px 30px"
                    marginTop={['10px', '10px', '0']}
                    h="35px"
                    size="sm"
                    // alignSelf="flex-end"
                    alignSelf={['center', 'center', "flex-end"]}
                    _hover={{ backgroundColor: "lighterPrimary" }}
                    onClick={() => onSubmit(inputs)}
                    isLoading={isLoading}
                  >
                    Włącz pakiet
                  </Button>
                </>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
