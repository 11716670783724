import { FC, useEffect, useRef, useState } from "react";
import { Button, Flex } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { DOTS, IPaginationProps, usePagination } from "../../../hooks/usePagination";

interface IPagination {
  onPageChange: (value: number) => void;
  maxWidth?: string;
}

export const Pagination: FC<IPaginationProps & IPagination> = ({
  currentPage,
  totalCount,
  siblingCount,
  pageSize,
  onPageChange,
  maxWidth,
}) => {
  const paginationRef = useRef<HTMLDivElement>(null);
  const [maxVisibleButtons, setMaxVisibleButtons] = useState<number>(5); // Default max buttons
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (paginationRef.current) {
  //       const containerWidth = paginationRef.current.offsetWidth;

  //       // Adjust maxVisibleButtons based on container width
  //       const buttons = Math.floor(containerWidth / 60); // Assume each button is ~60px wide
  //       setMaxVisibleButtons(buttons > 5 ? buttons : 5); // Ensure at least 5 buttons are shown
  //       // setMaxVisibleButtons(buttons); // Ensure at least 5 buttons are shown
  //     }
  //   };

  //   handleResize(); // Initial calculation
  //   const observer = new ResizeObserver(handleResize);
  //   if (paginationRef.current) observer.observe(paginationRef.current);

  //   return () => observer.disconnect();
  // }, []);

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  const visiblePaginationRange =
    paginationRange?.slice(0, maxVisibleButtons) || [];

  return (
    <Flex mt="10px" justify="center" maxW={maxWidth} ref={paginationRef}>
      {currentPage !== 1 && (
        <Button
          mx="3px"
          color="darkPrimary"
          borderColor="darkPrimary"
          onClick={onPrevious}
          variant="outline"
        >
          <ChevronLeftIcon />
        </Button>
      )}

      {paginationRange && paginationRange.map((pageNumber, index) => {
        // {visiblePaginationRange &&visiblePaginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <Button
              key={pageNumber + index}
              mx="3px"
              color="darkPrimary"
              borderColor="darkPrimary"
              variant="disabled"
            >
              &#8230;
            </Button>
          );
        }
        if ((Number(pageNumber) >= 3 && Number(pageNumber) <= paginationRange.length - 3 && (Math.abs(Number(pageNumber) - Number(currentPage)) < 4)) || ((Number(pageNumber) < 3 || Number(pageNumber) > paginationRange.length - 3) && (Math.abs(Number(pageNumber) - Number(currentPage)) < 2))) {
          // if ((Number(pageNumber) >= 3 && Number(pageNumber) <= visiblePaginationRange.length - 3 && (Math.abs(Number(pageNumber) - Number(currentPage)) < 4)) || ((Number(pageNumber) < 3 || Number(pageNumber) > visiblePaginationRange.length - 3) && (Math.abs(Number(pageNumber) - Number(currentPage)) < 2))) {

          return (
            <Button
              key={pageNumber}
              mx="3px"
              color="darkPrimary"
              borderColor="darkPrimary"
              onClick={() => onPageChange(Number(pageNumber))}
              variant={currentPage === pageNumber ? "solid" : "outline"}
            >
              {pageNumber}
            </Button>
          );

        }

      })}

      {/* {visiblePaginationRange?.length && currentPage !== visiblePaginationRange[visiblePaginationRange.length - 1] && ( */}
      {currentPage !== lastPage && (
        <Button mx="3px" colorScheme="blue" onClick={onNext} variant="outline">
          <ChevronRightIcon />
        </Button>
      )}
    </Flex>
  );
};

