import { Box, Container, Fade } from "@chakra-ui/react";
import { FC } from "react";
import { useParams } from "react-router-dom";

import { useTelevisionPackagesList } from "../../hooks/useTelevisionPackages";
import {
  Banner,
  Card,
  CustomedSpinner,
  PackagesTable,
  PageHeader,
} from "../../shared/components";
import bannerImage from "../../img/bannerTv/telew_top.jpg";
import { BannerClass } from "../../shared/components/Banner/utils";
import { useUserData } from "../../hooks/useUserData";
import { useTelevisionList } from "../../hooks/useTelevisionList";
import { env } from "../../env";

export const Package: FC = () => {
  const { id } = useParams();
  const packages = useTelevisionPackagesList(id || "0");

  const userData = useUserData();
  const { data } = useTelevisionList();

  const packageName = data.find((element) => element.id === id)?.name;

  const bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno);

  if (packages.isLoading) {
    return <CustomedSpinner />;
  }

  return (
    <>
      <Banner bannerImage={bannerImage} {...bannerDetails} />
      <Fade in={true}>
        <Container maxWidth={"container.xl"} flexDir={"column"}  >
          <PageHeader title={packageName} buttonText="Telewizja" />
          <Box>
            <Card title="Włączone pakiety i usługi" childrenStyle={{ padding: '10px 0px' }} >
              <PackagesTable packages={packages.data.active} />
            </Card>
          </Box>
          <Box m="20px 0px">
            <Card title="Dostępne pakiety dodatkowe" childrenStyle={{ padding: '10px 0px' }} >
              <PackagesTable
                packages={packages.data.available}
                iptvAccountId={id}
              />
            </Card>
          </Box>
        </Container>
      </Fade>
    </>
  );
};
