import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton, Container, Grid, GridItem, Fade, Flex, Text } from '@chakra-ui/react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import bannerImage from '../../../src/img/bannerSettlements/rozliczenia_top.jpg';
import { useContractDetails } from '../../hooks/useContractsDetails';
import { useUserData } from '../../hooks/useUserData';
import {
    Banner,
    Card,
    Detail,
    StatusBadge,
    ContactFormModal,
    CustomedButton,
    CustomedSpinner,
    ContractTerminationModal,
    ContractAutoExtResignationModal,
    PageHeader,
    CustomedInput,
} from '../../shared/components';
import { BannerClass } from '../../shared/components/Banner/utils';
import { capitalizeFirstLetter } from '../../shared/utils';
import { useContractSms } from '../../hooks/useContractSms';
import { useDeclarationTypes } from "../../hooks/useDeclarationTypes";
import { env } from '../../env';
import { useFirm } from '../../hooks/useFirm';

interface IContractSms {
    pin?: string;
}

// const testHtml = '<div>as</div>'

export const Contract: FC = () => {
    let { id } = useParams();
    const userData = useUserData(),

        { isLoading, isSuccess, isError, errors, action, contractSms, resetForm } =
            useContractSms(id || '0'),
        [firstSmsSend, setFirstSmsSend] = useState(false),
        [inputs, setInputs] = useState<IContractSms>({}),

        contractDetails = useContractDetails(id || '0'),
        declarationTypes = useDeclarationTypes(id || "0"),
        { date, name, status, status_desc, html, blocked } = contractDetails?.data,
        { ebok_rights, speedtest_url } = useFirm().data,

        [isPageLoading, setIsPageLoading] = useState(true),

        sendSMS = (withPin?: boolean) => {
            contractSms(withPin ? inputs : {});
        },

        handleInputChange = (key: string, value: string) => {
            setInputs((oldState) => {
                const newState = { ...oldState, [key]: value };
                return newState;
            });
        },

        bannerDetails = new BannerClass(env.REACT_APP_USE_USER_CODE === 'true' ? userData.data?.usercode : userData.data?.userno),
        d = new Date(new Date().setDate(new Date().getDate() + 7)),
        nowDate = d.toISOString().split('T')[0];

    useEffect(() => {
        if (firstSmsSend === false && isSuccess === true) {
            setFirstSmsSend(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        // console.log('ebok_rights: ' + JSON.stringify(ebok_rights))
        if (contractDetails.isLoading && !contractDetails.data) {
            setIsPageLoading(true);
        } else {
            // console.log('contract: ' + JSON.stringify(contractDetails.data.html))
            setTimeout(() => {
                setIsPageLoading(false);
            }, 300);
        }
    }, [contractDetails/* , ebok_rights */]);

    if (isPageLoading) {
        return <CustomedSpinner />;
    }

    const createProperHtml = () => {
        const wrappedHtml = '<table align ="center"><tr><td>' + html + '</td></tr></table>';

        return { __html: wrappedHtml }
        // return {__html: testHtml}
        // return {__html: 'test'}
    }

    return (
        <>
            <Banner
                extraContent={
                    <ContactFormModal button={
                        <CustomedButton centered={true} fontSize="22px" height="50px" label="Przedłuż umowę" />
                    } action="contract" />
                }
                bannerImage={bannerImage}
                {...bannerDetails}
            />
            <Fade in={true}>
                <Container maxWidth={"container.xl"} flexDir={"column"}>
                    <PageHeader title="Umowa" buttonText="Finanse" />
                    <Box>
                        <Grid templateColumns={status === "1" || status_desc === "nieaktywna" ? "repeat(2)" : "repeat(2, 1fr)"} gap={30} mb="20px">
                            <GridItem colSpan={[2, 1, 1]}>
                                <Card>
                                    <Flex flexWrap={"wrap"}>
                                        <Detail title="Dokument" textSize="20px" color="black" m={["0 70px 20px 0", "0 70px 20px 0", "0 70px 0px 0"]}>
                                            {name}
                                        </Detail>
                                        <Detail title="Data wystawienia" textSize="20px" color="black" m={["0 70px 20px 0", "0 70px 20px 0", "0 70px 0px 0"]}>
                                            {date}
                                        </Detail>
                                        <Flex flexDir="column" mb={["20px", "20px", "0px"]}>
                                            <Text fontSize="14px" color="lightGray" textTransform="uppercase">
                                                Status:
                                            </Text>
                                            {status === "1" && blocked === "0" ? (
                                                <StatusBadge width="100%" label={capitalizeFirstLetter(status_desc || "Aktywna")} status="active" style={{ paddingLeft: "5px", paddingRight: "5px" }} />
                                            ) : (
                                                <StatusBadge width="100%" label={capitalizeFirstLetter(status_desc || "Niepodpisana")} status="inactive"
                                                    style={{ paddingLeft: "10px", paddingRight: "5px" }} />
                                            )}
                                        </Flex>
                                        {ebok_rights?.includes('6') && status === "1" && blocked === "0" && (
                                            <Flex justifySelf="flex-end" alignItems="center" ml={["0", "0", "auto"]} width={["100%", "100%", "auto"]} wrap="wrap">
                                                <ContractTerminationModal contractDate={date} contractNumber={name} button={
                                                    <Button size="sm" fontWeight={500} backgroundColor="darkerPrimary" _hover={{ backgroundColor: "lightPrimary" }} width={["100%", "100%", "auto"]}
                                                        m={["10px 0px", "10px 0px", "0px 10px"]} color="white">
                                                        Wypowiedzenie umowy
                                                    </Button>
                                                } />
                                                {nowDate < contractDetails.data?.actual_contractexpiredate.split(' ')[0] && contractDetails.data?.actual_contractexpiredate != '0' && (
                                                    <ContractAutoExtResignationModal contractDate={date} contractNumber={name} button={
                                                        <Button
                                                            size="sm" fontWeight={500} backgroundColor="darkerPrimary" _hover={{ backgroundColor: "lightPrimary" }} width={["100%", "100%", "auto"]}
                                                            m={["5px 0px", "5px 0px", "0px 10px"]} color="white">
                                                            Rezygnacja z auto przedłużania
                                                        </Button>
                                                    } />
                                                )}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Card>
                            </GridItem>
                            <GridItem colSpan={[2, 1, 1]}>
                                {status === "0" && status_desc === "niepodpisana" && (
                                    <Card>
                                        <Text fontSize="18px" color="black" fontWeight={600}>
                                            Zapoznaj się z umową i wpisz kod SMS aby ją potwierdzić.
                                        </Text>

                                        {isError && (
                                            <Alert status="error" borderRadius="8px" my="10px">
                                                <AlertIcon />
                                                <AlertTitle mr={2}>Wystąpił błąd!</AlertTitle>
                                                <AlertDescription mr={"15px"}>
                                                    {errors.join(" ")}
                                                </AlertDescription>
                                                <CloseButton position="absolute" right="8px" top="8px" onClick={resetForm} />
                                            </Alert>
                                        )}

                                        {isSuccess && action === "sms_send" && (
                                            <Alert status="success" borderRadius="8px" my="10px">
                                                <AlertIcon />
                                                <AlertDescription mr={"15px"}>
                                                    SMS został wysłany.
                                                </AlertDescription>
                                                <CloseButton position="absolute" right="8px" top="8px" onClick={resetForm} />
                                            </Alert>
                                        )}

                                        {status !== "0" ? null : isSuccess && action === "sign_confirmed" ? (
                                            <Alert status="success" borderRadius="8px" my="10px">
                                                <AlertIcon />
                                                <AlertDescription mr={"15px"}>
                                                    Podpisanie umowy powiodło się
                                                </AlertDescription>
                                            </Alert>
                                        ) : (
                                            <Flex align="center" mt={["0px", "0px", "20px"]} flexDir={["column", "column", "row"]} flexWrap="wrap">
                                                <Box width={["100%", "100%", "200px"]} mr={["0px", "0px", "20px"]}>
                                                    <CustomedInput label="Kod SMS" width={["100%", "100%", "200px"]} isDisabled={!firstSmsSend} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                        handleInputChange("pin", event.target.value);
                                                    }} />
                                                </Box>
                                                <CustomedButton label="Potwierdź" backgroundColor="lightPrimary" alignSelf="flex-end" height="35px" width={["100%", "100%", "auto"]}
                                                    mr={["0px", "0px", "20px", "20px"]} mt="10px" disabled={
                                                        !firstSmsSend ||
                                                        inputs.pin?.length === undefined ||
                                                        inputs.pin?.length < 1
                                                    } isLoading={isLoading && action === "sms_send"} onClick={() => sendSMS(true)} />
                                                <CustomedButton label={
                                                    firstSmsSend ? "Wyślij kod ponownie" : "Wyślij Kod"
                                                } backgroundColor="lightPrimary" alignSelf="flex-end" height="35px" width={["100%", "100%", "auto"]} mt="10px" onClick={() => sendSMS()}
                                                    isLoading={isLoading && action !== "sms_send"} />
                                            </Flex>
                                        )}
                                    </Card>
                                )}
                            </GridItem>
                        </Grid>
                    </Box>
                    <Box mt="20px">
                        <Card title={"Szczegóły " + name}>
                            {html ? (
                                <>
                                    <Box display={["none", "flex", "flex"]} w={'100%'} overflowX={'auto'} paddingTop="20px" dangerouslySetInnerHTML={createProperHtml()} />
                                    <Text display={["flex", "none", "none"]} textAlign="center" fontSize="18px">
                                        Podgląd umowy widoczny jest w widoku poziomym, proszę obrócić urządzenie.
                                    </Text>
                                </>

                            ) : (
                                <Text display={["flex", "flex", "flex"]} textAlign="center" fontSize="16px">
                                    Podgląd umowy będzie dostępny wkrótce.
                                </Text>
                            )}
                        </Card>
                    </Box>
                </Container>
            </Fade>
        </>
    );
};
